import $ from "jquery";
import App from "@scripts/src/App";

(async () => {
  const ACCORDION_AUTO_CLOSE = $(".menu").data("accordion-auto-close");
  const BREAKPOINT = 768;

  const updateTransform = ($el, transformValue) => {
    if ($el.length) {
      $el.css("transform", transformValue);
    }
  };

  const getTransformValueByBurgerPlacement = (isBurgerPlacedRight, isVisible) =>
    isVisible ? "translateX(0)" : isBurgerPlacedRight ? "translateX(100%)" : "translateX(-100%)";

  const closeAccordionSubcategories = () => {
    $(".js-subcategories-accordion-toggle").each(function () {
      const $subcategory = $(this).closest(".item").find(".subcategories");
      if ($subcategory.length) {
        $subcategory.hide();
        $(this).removeClass("expand");
      }
    });
  };

  const toggleAccordionSubcategory = ($toggle) => {
    const $subcategory = $toggle.closest(".item").find(".subcategories").first();
    const isOpen = $subcategory.is(":visible");

    if (ACCORDION_AUTO_CLOSE) closeAccordionSubcategories();

    if ($subcategory.length) {
      $subcategory.toggle(!isOpen);
      $toggle.toggleClass("expand", !isOpen);
    }
  };

  const applySubcategoryTransform = (transformValue) => {
    $(".js-subcategories-reveal-target").each(function () {
      const $target = $(this);
      const $parentItem = $target.closest(".item");
      if ($parentItem.find(".js-subcategories-reveal-open").length) {
        $target.css("transform", transformValue);
      }
    });
  };

  const closeMenu = () => {
    const $menuToggle = $(".js-menu-toggle");
    const isBurgerPlacedRight = $menuToggle.hasClass("js-burger-placed--end");
    const transformValue = getTransformValueByBurgerPlacement(isBurgerPlacedRight, false);
    const $menu = $(".menu");

    if ($menu.length) {
      updateTransform($menu, transformValue);
      $menu.removeClass("menu--open");
    }
    App.scrollUnblock("menu");
    App.removeUnderlay("menu");
    if ($(window).width() < BREAKPOINT) {
      setTimeout(() => applySubcategoryTransform(transformValue), 600);
    }

    closeAccordionSubcategories();
  };

  const toggleMenu = ($el, show) => {
    $el.on("click", function (event) {
      event.stopPropagation();
      const isBurgerPlacedRight = $el.hasClass("js-burger-placed--end");
      const transformValue = getTransformValueByBurgerPlacement(isBurgerPlacedRight, show);
      const $menu = $(".menu");

      if ($menu.length) {
        updateTransform($menu, transformValue);

        if (show) {
          $menu.addClass("menu--open");
          App.scrollBlock("menu");
          App.addUnderlay("menu");
        } else {
          $menu.removeClass("menu--open");
          App.scrollUnblock("menu");
          App.removeUnderlay("menu");

          if ($(window).width() < BREAKPOINT) {
            setTimeout(() => applySubcategoryTransform(transformValue), 600);
          }

          closeAccordionSubcategories();
        }
      }
    });
  };

  const handleResize = () => {
    $(".js-subcategories-reveal-target").css("transform", "");

    if ($(window).width() < BREAKPOINT) {
      closeAccordionSubcategories();
    }
  };

  // Initial setup
  toggleMenu($(".js-menu-toggle"), true);
  toggleMenu($(".js-menu-close"), false);

  $(window).resize(handleResize);

  // Event listeners
  $(document).on("click", ".js-subcategories-accordion-toggle", function (event) {
    event.stopPropagation();
    toggleAccordionSubcategory($(this));
  });

  $(".js-subcategories-reveal-open").on("click", function (e) {
    if ($(window).width() < BREAKPOINT) {
      e.preventDefault();
      const $target = $(this).closest(".item").find(".js-subcategories-reveal-target");
      $target.css("transform", "translateX(0)");
    }
  });

  $(".js-subcategories-reveal-close").on("click", function (event) {
    event.stopPropagation();
    if ($(window).width() < BREAKPOINT) {
      const $target = $(this).closest(".js-subcategories-reveal-target");
      const $menuToggle = $(".js-menu-toggle");
      const isBurgerPlacedRight = $menuToggle.hasClass("js-burger-placed--end");
      const transformValue = getTransformValueByBurgerPlacement(isBurgerPlacedRight, false);
      $target.css("transform", transformValue);
    }
    closeAccordionSubcategories();
  });

  $(document).on("click", (event) => {
    if (
      !$(event.target).closest(
        ".menu, .js-menu-toggle, .js-subcategories-reveal-target, .js-subcategories-reveal-close",
      ).length
    ) {
      closeMenu();
    }
  });

  // Menu products show on hover implementation
  const triggerMenuProducts = document.querySelector(".site-header .mega-menu li:first-child");
  const menuProducts = document.querySelector(".menu-products");

  // Function to show the menu
  function showMenu() {
    menuProducts.style.display = "block";
  }

  // Function to hide the menu
  function hideMenu() {
    menuProducts.style.display = "none";
  }

  // Add event listeners to show and hide the menu
  triggerMenuProducts.addEventListener("mouseenter", showMenu);
  menuProducts.addEventListener("mouseenter", showMenu); // Keeps menu visible when hovered
  triggerMenuProducts.addEventListener("mouseleave", () => {
    setTimeout(() => {
      // Check if the menu is not being hovered before hiding it
      if (!menuProducts.matches(":hover")) {
        hideMenu();
      }
    }, 100);
  });

  menuProducts.addEventListener("mouseleave", () => {
    setTimeout(() => {
      // Check if the triggerMenuProducts is not being hovered before hiding the menu
      if (!triggerMenuProducts.matches(":hover")) {
        hideMenu();
      }
    }, 100);
  });
})();
